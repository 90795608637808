import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class PageHeader extends Component{
  render(){
    return(
      <header>
        <h1>Vlaamse Meesters in Situ campagne online template</h1>
      </header>
    )
  }
}

class PageFooter extends Component{
  render(){
    return(
      <footer>
        Made in <a href="https://www.josworld.org">Josworld</a>. Support: <a href="mailto:okv@josworld.org">okv@josworld.org</a>.
      </footer>
    )
  }
}

class Layout extends Component{
  render(){
    return(
      <div class="layout--container">
        <CanvasElement/>
        
      </div>
    );
  }
}

var pageLayout = {};

// Cutoff voor ratio
// 1: vierkant
var ratioInt = 1.15;


function generateElements(layoutWidth, layoutHeight, imageWidth, imageHeight, text, textLocation, date, showText, type){
  // Text
  pageLayout.fontSize = parseInt(parseFloat(layoutWidth/30));
  pageLayout.lineHeight = pageLayout.fontSize * 1.5;
  pageLayout.totalTextHeight = pageLayout.lineHeight * 2;
  pageLayout.fontColor = "#302d7f";
  pageLayout.text = text;
  pageLayout.textLocation = textLocation;
  pageLayout.date = date;
  pageLayout.type = type;

  pageLayout.image = [];

  pageLayout.width = parseFloat(layoutWidth);
  pageLayout.height = parseFloat(layoutHeight);

  pageLayout.ratio = pageLayout.width / pageLayout.height;

  pageLayout.frame = [];

  // Border
  pageLayout.border = [];
  pageLayout.border.color = '#e8412c';

  pageLayout.border.width = layoutWidth / 30;
  

  pageLayout.showText = showText;

  if(pageLayout.showText){
    if(pageLayout.ratio < ratioInt){
      // Vertical
      pageLayout.frame.width = pageLayout.width;
      pageLayout.frame.height = pageLayout.height / 2;
      pageLayout.padding = layoutWidth / 10;
      if(pageLayout.ratio < .5 && showText){
        pageLayout.border.width = layoutWidth / 10;
        pageLayout.frame.height = pageLayout.height / 3;
      }
    }else{
      pageLayout.frame.height = pageLayout.height;
      pageLayout.frame.width = pageLayout.width / 2;
      pageLayout.padding = layoutHeight / 10;
      pageLayout.border.width = layoutHeight / 20;
      if(pageLayout.ratio > 1.5 && showText){
        pageLayout.frame.width = pageLayout.width / 3;
      }
    }
  }else{
    pageLayout.padding = layoutWidth / 10;
    pageLayout.frame.width = pageLayout.width;
    pageLayout.frame.height = pageLayout.height;
  }

  // Flanders flag
  pageLayout.badge = [];
  pageLayout.badge.url = 'logo_nl.jpg';
  pageLayout.badge.ratio = 1063/1228;
  pageLayout.badge.opacity = .5;
  pageLayout.badge.color = 'rgba(0, 0, 0, ' + pageLayout.badge.opacity + ')'; 
  
  
  pageLayout.badge.width = pageLayout.frame.width/10;
    pageLayout.badge.height = pageLayout.badge.width / pageLayout.badge.ratio;
  
  

  // Logo bar
  pageLayout.logoBar = [];
  pageLayout.logoBar.url = 'okv-logobar.png';
  pageLayout.logoBar.ratio = 4596/333;
  pageLayout.logoBar.color = "blue";

  pageLayout.situ = [];
  pageLayout.situ.url = 'situ.svg';
  pageLayout.situ.ratio = 643.86/61.05;


  /*pageLayout.frame.height = checkForAvailableSpace().height;
  pageLayout.frame.width = checkForAvailableSpace().width;
*/
  //pageLayout.pageHeight = layoutHeight * 1.5;

  //pageLayout.padding = pageLayout.height / 10; // Minimum distance from border

  

  

  // Lines start
  pageLayout.lines = [];
  pageLayout.lines.color = '#000';
  pageLayout.lines.width = pageLayout.border.width * .5;
  pageLayout.lines.elements = [];

  // Image border
  pageLayout.imageBorder = [];
  pageLayout.imageBorder.color = '#000';
  pageLayout.imageBorder.width = pageLayout.lines.width/1.5;


  
  // SVG Logo
  pageLayout.logoWidth = pageLayout.width - pageLayout.padding * 2;
  pageLayout.logoHeight = pageLayout.logoWidth * .25;

  pageLayout.image.ratio = imageWidth / imageHeight;
  pageLayout.image.source = [];
  pageLayout.image.source.width = imageWidth;
  pageLayout.image.source.height = imageHeight;

  pageLayout.image.width = pageLayout.frame.width / 2;
  pageLayout.image.height = pageLayout.frame.height / 2;

  if(pageLayout.image.width > pageLayout.frame.width - pageLayout.padding - pageLayout.imageBorder.width){
    pageLayout.image.width = pageLayout.image.width / 3;
  }

  if(pageLayout.image.height > pageLayout.frame.height - pageLayout.padding - pageLayout.imageBorder.width){
    pageLayout.image.height = pageLayout.image.height / 3;
  }

  // Checks orientation
  if(pageLayout.image.ratio > 1){
    pageLayout.image.height = pageLayout.image.width / pageLayout.image.ratio;
    // Horizontal
  }else{
    pageLayout.image.width = pageLayout.image.height * pageLayout.image.ratio;
    // Vertical
  }

  if(pageLayout.image.width > pageLayout.frame.width - pageLayout.padding - pageLayout.imageBorder.width){
    pageLayout.image.width = pageLayout.image.width * .5;
    pageLayout.image.height = pageLayout.image.width / pageLayout.image.ratio;
  }

  if(pageLayout.image.height > pageLayout.frame.height - pageLayout.padding - pageLayout.imageBorder.width){
    pageLayout.image.height = pageLayout.image.height * .5;
    pageLayout.image.width = pageLayout.image.height * pageLayout.image.ratio;
  }
  

  // Random distance
  pageLayout.offsetX = 
    Math.round(Math.random() * (
      pageLayout.frame.width - 
      pageLayout.padding * 2 - 
      pageLayout.imageBorder.width * 2 - pageLayout.image.width)
    );
  pageLayout.offsetY = 
    Math.round(Math.random() * (
      pageLayout.frame.height - 
      pageLayout.padding * 2 - 
      pageLayout.imageBorder.width * 2 - pageLayout.image.height)
    );
  

  /*
    startPosX: minimum distance from border
    offsetX: random offset
    + 10: distance to compensate for line width
    - 15: Width of black border around image
  */

  // Top left line
  pageLayout.lines.elements.push([
    [pageLayout.border.width + pageLayout.lines.width, pageLayout.border.width], 
    [pageLayout.padding + pageLayout.offsetX + pageLayout.lines.width/1.5 - pageLayout.imageBorder.width, pageLayout.padding + pageLayout.offsetY - pageLayout.imageBorder.width],
    [pageLayout.padding + pageLayout.offsetX - pageLayout.imageBorder.width, pageLayout.padding + pageLayout.offsetY + pageLayout.lines.width/1.5 - pageLayout.imageBorder.width],
    [pageLayout.border.width, pageLayout.border.width + pageLayout.lines.width],
    [pageLayout.border.width, pageLayout.border.width]
  ]);

  // Top right line
  pageLayout.lines.elements.push([
    [pageLayout.frame.width - pageLayout.border.width - pageLayout.lines.width, pageLayout.border.width], 
    [pageLayout.padding + pageLayout.offsetX + pageLayout.image.width - pageLayout.lines.width/1.5 + pageLayout.imageBorder.width, pageLayout.padding + pageLayout.offsetY - pageLayout.imageBorder.width],
    [pageLayout.padding + pageLayout.offsetX + pageLayout.image.width + pageLayout.imageBorder.width, pageLayout.padding + pageLayout.offsetY - pageLayout.imageBorder.width + pageLayout.lines.width/1.5],
    [pageLayout.frame.width - pageLayout.border.width, pageLayout.border.width + pageLayout.lines.width],
    [pageLayout.frame.width - pageLayout.border.width, pageLayout.border.width]
  ]);

  // Bottom right line
  pageLayout.lines.elements.push([
    [pageLayout.frame.width - pageLayout.border.width, pageLayout.frame.height - pageLayout.border.width - pageLayout.lines.width], 
    [pageLayout.padding + pageLayout.offsetX + pageLayout.image.width + pageLayout.imageBorder.width, pageLayout.padding + pageLayout.image.height + pageLayout.offsetY + pageLayout.imageBorder.width - pageLayout.lines.width/1.5],
    [pageLayout.padding + pageLayout.offsetX + pageLayout.image.width - pageLayout.lines.width/1.5 + pageLayout.imageBorder.width, pageLayout.padding + pageLayout.image.height + pageLayout.offsetY + pageLayout.imageBorder.width],
    [pageLayout.frame.width - pageLayout.border.width - pageLayout.lines.width, pageLayout.frame.height - pageLayout.border.width],
    [pageLayout.frame.width - pageLayout.border.width, pageLayout.frame.height - pageLayout.border.width]
  ]);

  // Bottom left line
  pageLayout.lines.elements.push([
    [pageLayout.border.width, pageLayout.frame.height - pageLayout.border.width - pageLayout.lines.width], 
    [pageLayout.padding + pageLayout.offsetX - pageLayout.imageBorder.width, pageLayout.padding + pageLayout.image.height + pageLayout.offsetY + pageLayout.imageBorder.width - pageLayout.lines.width/1.5],
    [pageLayout.padding + pageLayout.offsetX + pageLayout.lines.width/1.5 - pageLayout.imageBorder.width, pageLayout.padding + pageLayout.image.height +  pageLayout.offsetY + pageLayout.imageBorder.width],
    [pageLayout.border.width + pageLayout.lines.width, pageLayout.frame.height - pageLayout.border.width],
    [pageLayout.border.width, pageLayout.frame.height - pageLayout.border.width]
  ]);

  // Image
  pageLayout.image.position = [pageLayout.border.width + pageLayout.padding + pageLayout.offsetX, pageLayout.border.width + pageLayout.padding + pageLayout.offsetY, pageLayout.image.width, pageLayout.image.height]

  pageLayout.inch = pageLayout.image.width/25.4;
  pageLayout.dpi = parseInt(pageLayout.image.source.width / (pageLayout.image.width/25.4));
  document.querySelector('.dpi').innerHTML = pageLayout.dpi + " dpi";
  if(pageLayout.dpi >= 240){
    document.querySelector('.dpi').className = "dpi dpi--ok";
  }else{
    document.querySelector('.dpi').className = "dpi dpi--not-ok";
    document.querySelector('.dpi').innerHTML += "<br/>(niet voldoende, de afbeelding moet minstens " + parseInt(pageLayout.inch*300) + "px breed zijn)";
  }
    
  return pageLayout;
}


function showCanvas(){
  var canvas = document.getElementById('layout');
  canvas.width = pageLayout.width;
  canvas.height = pageLayout.height;
  if (canvas.getContext) { // Checks if context exists
    var ctx = canvas.getContext('2d');

      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, pageLayout.width, pageLayout.height);

      // Border
      ctx.fillStyle = pageLayout.border.color;
      ctx.fillRect(0, 0, pageLayout.frame.width, pageLayout.frame.height);
      ctx.fillStyle = '#fff';
      ctx.fillRect(
        pageLayout.border.width, 
        pageLayout.border.width, 
        pageLayout.frame.width - 2 * pageLayout.border.width, 
        pageLayout.frame.height - 2 * pageLayout.border.width);
      

      ctx.fillStyle = pageLayout.lines.color;
      for(let a = 0; a < pageLayout.lines.elements.length; a++){
        ctx.beginPath();
        for(let b = 0; b < pageLayout.lines.elements[a].length; b++){
          if(b == 0){
            ctx.moveTo(
              pageLayout.lines.elements[a][b][0],
              pageLayout.lines.elements[a][b][1]
            );
          }else{
            ctx.lineTo(
              pageLayout.lines.elements[a][b][0],
              pageLayout.lines.elements[a][b][1]
            );
          }
        }
        ctx.fill();
      }

      var pageIsVertical = false;

      if(pageLayout.showText){
        if(pageLayout.ratio < ratioInt){
          pageIsVertical = true;
        }
  
        let textWidth = pageLayout.width - pageLayout.padding * 2;
        let textStartX = pageLayout.padding;
        let textStartY =  pageLayout.frame.height + pageLayout.padding * 1.5;
  
        if(!pageIsVertical){
          textStartY = pageLayout.padding * 1.25;
          textStartX =  pageLayout.frame.width + pageLayout.padding * .5;
          textWidth = pageLayout.width - pageLayout.frame.width - pageLayout.padding * .5 * 2;
        }
        
        pageLayout.typography = [];
        pageLayout.typography.textStartX = textStartX;
        pageLayout.typography.textStartY = textStartY;
        pageLayout.typography.textWidth = textWidth;
  
  
        pageLayout.typography.logo = [];
        pageLayout.typography.logo.text = "Overmeesterd!"
        var logoSize = 500;
        ctx.font = '700 ' + logoSize + 'px/' + logoSize + 'px Bitter';
        var logo = ctx.measureText(pageLayout.typography.logo.text); // TextMetrics object
  
        if(logo.width > textWidth){
          while(logo.width > textWidth){
            logoSize--;
            ctx.font = '700 ' + logoSize + 'px/' + logoSize + 'px Bitter';
            logo = ctx.measureText(pageLayout.typography.logo.text); // TextMetrics object
          }
        }

        var scaleFactor = 1;
        logoSize = logoSize * scaleFactor;

        if(!pageIsVertical){
          textStartY = pageLayout.padding + logoSize*.85;
          pageLayout.typography.textStartY = textStartY;
        }

        pageLayout.logoBar.width = textWidth * scaleFactor;
        pageLayout.logoBar.height = textWidth / pageLayout.logoBar.ratio * scaleFactor;

        pageLayout.fontSize = logoSize / 2.5;
        pageLayout.lineHeight = pageLayout.fontSize * 1.5;

        pageLayout.situ.width = textWidth * .7 * scaleFactor;
        pageLayout.situ.height = pageLayout.situ.width / pageLayout.situ.ratio;
        pageLayout.situ.y = pageLayout.typography.textStartY + pageLayout.lineHeight * 4;
        pageLayout.situ.x = pageLayout.typography.textStartX;

        while((pageLayout.padding + pageLayout.logoBar.height + (pageLayout.situ.y + pageLayout.situ.height)) > pageLayout.height){
          scaleFactor = scaleFactor - .05;

          logoSize = logoSize * scaleFactor;
          pageLayout.fontSize = logoSize / 2.5;
          pageLayout.lineHeight = pageLayout.fontSize * 1.5;

          if(!pageIsVertical){
            textStartY = pageLayout.padding + logoSize*.85;
            pageLayout.typography.textStartY = textStartY;
          }

          pageLayout.situ.width = pageLayout.situ.width * scaleFactor;
          pageLayout.situ.height = pageLayout.situ.width / pageLayout.situ.ratio;
          pageLayout.situ.y = pageLayout.typography.textStartY + pageLayout.lineHeight * 4;
          pageLayout.situ.x = pageLayout.typography.textStartX;

          pageLayout.logoBar.width = textWidth * scaleFactor;
          pageLayout.logoBar.height = textWidth / pageLayout.logoBar.ratio * scaleFactor;
        }

        pageLayout.typography.logo.fontSize = logoSize;
        pageLayout.typography.logo.lineHeight = logoSize;
        pageLayout.typography.logo.color = pageLayout.border.color;
  
        ctx.font = '700 ' + logoSize + 'px/' + logoSize + 'px Bitter';
        ctx.fillStyle = pageLayout.typography.logo.color;
  
        ctx.fillText(pageLayout.typography.logo.text, pageLayout.typography.textStartX, pageLayout.typography.textStartY);
        
  
        

        
        pageLayout.typography.copy = [];
        pageLayout.typography.copy.text = "Beleef een Vlaamse Meester";
  
        ctx.fillStyle = pageLayout.fontColor;
        
        ctx.font = '700 ' + pageLayout.fontSize + 'px/' + pageLayout.lineHeight + 'px Bitter';
        ctx.fillText(pageLayout.text, textStartX, textStartY + pageLayout.lineHeight);
        ctx.fillText(pageLayout.textLocation, pageLayout.typography.textStartX, pageLayout.typography.textStartY + pageLayout.lineHeight * 2);
  
        ctx.font = '400 ' + pageLayout.fontSize + 'px/' + pageLayout.lineHeight + 'px Bitter';
        ctx.fillText(pageLayout.date, pageLayout.typography.textStartX, pageLayout.typography.textStartY + pageLayout.lineHeight * 3);


        

        
        //////
        // Check for logo upload
        //////
        if(document.getElementById('logofield').files[0]){ // Checks if image has been selected (won't show anything if not)
          let logo = document.getElementById('logofield').files[0]
          if(logo.type.startsWith('image/')){ // Checks if image has valid type
            const logoImg = document.createElement("img");
            logoImg.src = window.URL.createObjectURL(logo);

          // var img = new Image(); // Creates new image object for canvas

          
          

            // Starts when image is loaded
            logoImg.onload = function() {
              pageLayout.logoBar.logo = [];
              pageLayout.logoBar.logo.height = pageLayout.logoBar.height;
              pageLayout.logoBar.logo.width = logoImg.width / logoImg.height * pageLayout.logoBar.logo.height;

              pageLayout.logoBar.originalWidth = pageLayout.logoBar.width;
              pageLayout.logoBar.width = pageLayout.logoBar.width + pageLayout.logoBar.width/20 + pageLayout.logoBar.logo.width;

              pageLayout.logoBar.newRatio = textWidth / pageLayout.logoBar.width;

              pageLayout.logoBar.width = pageLayout.logoBar.width * pageLayout.logoBar.newRatio;
              pageLayout.logoBar.height = pageLayout.logoBar.height * pageLayout.logoBar.newRatio;
              pageLayout.logoBar.originalWidth = pageLayout.logoBar.originalWidth * pageLayout.logoBar.newRatio;

              ctx.drawImage(logoImg, 
                textStartX + pageLayout.logoBar.originalWidth + pageLayout.logoBar.originalWidth/20, 
                pageLayout.height - pageLayout.padding/2 - pageLayout.logoBar.height, 
                pageLayout.logoBar.logo.width, 
                pageLayout.logoBar.logo.height);
              drawBadge();

              const logoBar = document.createElement("img");
              logoBar.src = pageLayout.logoBar.url;

              // Starts when image is loaded
              logoBar.onload = function() {
                ctx.drawImage(logoBar, 
                  textStartX, pageLayout.height - pageLayout.padding/2 - pageLayout.logoBar.height, pageLayout.logoBar.originalWidth, pageLayout.logoBar.height);
              }
              logoBar.src = pageLayout.logoBar.url;

              const situ = document.createElement("img");
              situ.src = pageLayout.situ.url;

              // Starts when image is loaded
              situ.onload = function() {
                ctx.drawImage(situ, 
                  pageLayout.situ.x, pageLayout.situ.y, pageLayout.situ.width, pageLayout.situ.height);
              }
              situ.src = pageLayout.situ.url;
            }
            logoImg.src = window.URL.createObjectURL(logo);

            

          }
        }else{
          const logoBar = document.createElement("img");
          logoBar.src = pageLayout.logoBar.url;

          pageLayout.logoBar.originalWidth = pageLayout.logoBar.width;
          // Starts when image is loaded
          logoBar.onload = function() {
            ctx.drawImage(logoBar, 
              textStartX, pageLayout.height - pageLayout.padding/2 - pageLayout.logoBar.height, pageLayout.logoBar.width, pageLayout.logoBar.height);
                          
          }
          logoBar.src = pageLayout.logoBar.url;

          const situ = document.createElement("img");
          situ.src = pageLayout.situ.url;

          // Starts when image is loaded
          situ.onload = function() {
            ctx.drawImage(situ, 
              pageLayout.situ.x, pageLayout.situ.y, pageLayout.situ.width, pageLayout.situ.height);
          }
          situ.src = pageLayout.situ.url;
        }


        //// END ////

        

      }
      
      ctx.fillStyle = pageLayout.lines.color;
      ctx.fillRect(
        pageLayout.padding + pageLayout.offsetX - pageLayout.imageBorder.width, 
        pageLayout.padding + pageLayout.offsetY - pageLayout.imageBorder.width, 
        pageLayout.image.width + pageLayout.imageBorder.width*2, 
        pageLayout.image.height + pageLayout.imageBorder.width*2);
        

      function drawBadge(){
        const badge = document.createElement("img");
        badge.src = pageLayout.badge.url;

        // Starts when image is loaded
        badge.onload = function() {
          
          ctx.shadowColor = pageLayout.badge.color;
          ctx.shadowBlur = pageLayout.border.width / 2;
          ctx.drawImage(badge, 
            pageLayout.border.width * 2, pageLayout.border.width, pageLayout.badge.width, pageLayout.badge.height);
          // Overschrijft bovenste drop shadow + zet shadow op 0
          ctx.shadowBlur = 0;
          ctx.fillStyle = pageLayout.border.color;
          ctx.fillRect(pageLayout.border.width * 2 - pageLayout.border.width / 2, 0, pageLayout.badge.width + pageLayout.border.width, pageLayout.border.width);
                        
        }
        badge.src = pageLayout.badge.url;
      }

      if(document.getElementById('uploadfield').files[0]){ // Checks if image has been selected (won't show anything if not)
        let file = document.getElementById('uploadfield').files[0]
        if(file.type.startsWith('image/')){ // Checks if image has valid type
          const img = document.createElement("img");
          img.src = window.URL.createObjectURL(file);

        // var img = new Image(); // Creates new image object for canvas

          // Starts when image is loaded
          img.onload = function() {
            ctx.drawImage(img, 
              pageLayout.padding + pageLayout.offsetX, 
              pageLayout.padding + pageLayout.offsetY, 
              pageLayout.image.width, 
              pageLayout.image.height);
            drawBadge();
          }
          img.src = window.URL.createObjectURL(file);
        }
      }else{
        drawBadge();
      }
  }
}

function drawSVG(src, logoSrc = null){
  var lines = [];
  for(let a = 0; a < pageLayout.lines.elements.length; a++){
    var lineCoordinates = "";
    for(let b = 0; b < pageLayout.lines.elements[a].length; b++){
      lineCoordinates += pageLayout.lines.elements[a][b][0] + " " + pageLayout.lines.elements[a][b][1] + " ";
    }
    lines.push(<polyline points={lineCoordinates}></polyline>);
  }

  let text = [];
  let logoBar = [];

  if(pageLayout.showText){
    // TEXT
    var logoSize = 80;
    var logoStyles = {
      fontSize: pageLayout.typography.logo.fontSize,
      lineHeight: pageLayout.typography.logo.lineHeight,
      fill: pageLayout.typography.logo.color,
      fontFamily: "Bitter",
      fontWeight: 700
    }

    var textStyles = {
      fontSize: pageLayout.fontSize,
      lineHeight: pageLayout.lineHeight,
      fill: pageLayout.fontColor,
      fontFamily: "Bitter",
      fontWeight: 700
    }

    var dateStyles = {
      fontSize: pageLayout.fontSize,
      lineHeight: pageLayout.lineHeight,
      fill: pageLayout.fontColor,
      fontFamily: "Bitter",
      fontWeight: 400
    }

    text = (
      <g>
        <text style={logoStyles} x={pageLayout.typography.textStartX} y={pageLayout.typography.textStartY}>{pageLayout.typography.logo.text}</text>
        <text style={textStyles} x={pageLayout.typography.textStartX} y={pageLayout.typography.textStartY + pageLayout.lineHeight}>{pageLayout.text}</text>
        <text style={textStyles} x={pageLayout.typography.textStartX} y={pageLayout.typography.textStartY + pageLayout.lineHeight * 2}>{pageLayout.textLocation}</text>
        <text style={dateStyles} x={pageLayout.typography.textStartX} y={pageLayout.typography.textStartY + pageLayout.lineHeight * 3}>{pageLayout.date}</text>
      </g>
    );

    logoBar = (<image 
      xlinkHref={"../" + pageLayout.logoBar.url} 
      x={pageLayout.typography.textStartX} 
      y={pageLayout.height - pageLayout.padding} 
      width={pageLayout.logoBar.originalWidth} 
      height={pageLayout.logoBar.height}
    />);
  }
  
  let extraLogoImage = [];
  if(pageLayout.logoBar.logo){
    extraLogoImage = (<image 
      xlinkHref="[logoSrc]"
      x={pageLayout.typography.textStartX + pageLayout.logoBar.originalWidth + pageLayout.logoBar.originalWidth/20} 
      y={pageLayout.height - pageLayout.padding} 
      width={pageLayout.logoBar.logo.width}  
      height={pageLayout.logoBar.logo.height}/>);
  }
  // Drop shadow source: https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3
  return(
    <div class="pagelayoutcontainer">
    <svg width={pageLayout.width + 'mm'} height={pageLayout.height + 'mm'} class="pagelayout" viewBox={"0 0 " + pageLayout.width + " " + pageLayout.height}>
      <rect x="0" y="0" fill={pageLayout.border.color} width={pageLayout.frame.width} height={pageLayout.frame.height}></rect>
      <rect 
        x={pageLayout.border.width} 
        y={pageLayout.border.width} 
        fill="#fff" 
        width={pageLayout.frame.width - pageLayout.border.width * 2} 
        height={pageLayout.frame.height - pageLayout.border.width * 2}></rect>

      {lines}
      <rect 
        x={pageLayout.padding + pageLayout.offsetX - pageLayout.imageBorder.width} 
        y={pageLayout.padding + pageLayout.offsetY - pageLayout.imageBorder.width} 
        fill="#000" 
        width={pageLayout.image.width + pageLayout.imageBorder.width*2} 
        height={pageLayout.image.height + pageLayout.imageBorder.width*2}></rect>
      <image 
        xlinkHref="[imageSrc]"
        x={pageLayout.padding + pageLayout.offsetX} 
        y={pageLayout.padding + pageLayout.offsetY} 
        width={pageLayout.image.width}  
        height={pageLayout.image.height}/>
      {extraLogoImage}

      
      {text}

      <filter id="dropshadow" x="-50%" y="-50%" width="200%" height="200%" >
  <feGaussianBlur in="SourceAlpha" stdDeviation={pageLayout.border.width / 2}/>
  <feOffset dx="0" dy="0" result="offsetblur"/> 
  <feComponentTransfer>
    <feFuncA type="linear" slope={pageLayout.badge.opacity}/>
  </feComponentTransfer>
  <feMerge> 
    <feMergeNode/>
    <feMergeNode in="SourceGraphic"/>
  </feMerge>
</filter>
      <image 
        style={{filter: 'url(#dropshadow)'}}
        xlinkHref={"../" + pageLayout.badge.url} 
        x={pageLayout.border.width * 2} 
        y={pageLayout.border.width} 
        width={pageLayout.badge.width} 
        height={pageLayout.badge.height}
      />
      <rect x={pageLayout.border.width * 2 - pageLayout.border.width} y="0" fill={pageLayout.border.color} width={pageLayout.badge.width + pageLayout.border.width * 2} height={pageLayout.border.width}></rect>

      {logoBar}

      <image 
        xlinkHref={"../" + pageLayout.situ.url} 
        x={pageLayout.situ.x} 
        y={pageLayout.situ.y} 
        width={pageLayout.situ.width} 
        height={pageLayout.situ.height}
      />
    </svg>
    </div>
  )
}

class CanvasElement extends Component{
  componentDidMount() {
    //console.log(generateElements(200,300));
    //generateElements(this.state.layoutWidth, this.state.layoutHeight, 300, 300);
    //drawCanvas()
  }

  render(){
    return(
      <canvas id="layout"></canvas>
    );
  }
}

class Parameters extends Component{
  constructor(props) {
    super(props);
    this.state = {
      svgElement: "test",
      layoutWidth: 210,
      layoutHeight: 297,
      image: '',
      generatedLayout: '',
      text: 'Beleef een Vlaamse Meester',
      textLocation: 'in X vlakbij',
      date: 'Van 1 juni tot 30 september 2019',
      buttonVisible: 'none',
      downloadLink: '',
      downloadText: '',
      downloadFileName: '',
      showText: true,
      template: '210x297',
      downloadClass: '',
      logoData: '',
      type: 'print',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleImageInput = this.handleImageInput.bind(this);
    this.generateAndShowSVG = this.generateAndShowSVG.bind(this);
    this.addGeneratedLayout = this.addGeneratedLayout.bind(this);
    this.generatePreview = this.generatePreview.bind(this);
    this.generatePDFCode = this.generatePDFCode.bind(this);
    this.generatePNG = this.generatePNG.bind(this);
    this.generateJPEG = this.generateJPEG.bind(this);
    this.submitAction = this.submitAction.bind(this);
  }

  componentDidMount() {
    //generateElements(this.state.layoutWidth, this.state.layoutHeight, 300, 300, this.state.text);
    //this.generatePreview();
  }

  submitAction(event){
    document.querySelector('body').className = "loading-screen--visible";
    //event.preventDefault();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(target.type === 'radio'){
      this.setState({
        [name]: value,
        buttonVisible: 'none',
        downloadText: '',
        type: target.value,
      });
    }else{
      if(target.name === 'template'){
      if(target.value){
        this.setState({
          [name]: value,
          buttonVisible: 'none',
          downloadText: '',
          template: target.value,
          layoutWidth: target.value.split('x')[0],
          layoutHeight: target.value.split('x')[1],
        });
      }
    }else{
      this.setState({
        [name]: value,
        buttonVisible: 'none',
        downloadText: ''
      });
    }
    }
    
    
    //this.generatePreview();
    /* generateElements(this.state.layoutWidth, this.state.layoutHeight, 300, 300);
   
    drawCanvas();*/
  }

  handleImageInput(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      buttonVisible: 'none',
      downloadText: '',
      downloadClass: ''
    });
    
    this.generatePreview();
  }

  generatePreview(event){

    this.setState({
      buttonVisible: 'none',
      downloadText: '',
      downloadClass: ''
    });
    var layoutWidth = this.state.layoutWidth;
        var layoutHeight = this.state.layoutHeight;
        
    var parentThis = this;

    if(document.getElementById('uploadfield').files[0]){ // Checks if image has been selected (won't show anything if not)
        let file = document.getElementById('uploadfield').files[0]
        if(file.type.startsWith('image/')){ // Checks if image has valid type
          const img = document.createElement("img");
          img.src = window.URL.createObjectURL(file);

        // var img = new Image(); // Creates new image object for canvas

          // Starts when image is loaded
          img.onload = function() {
            generateElements(layoutWidth, layoutHeight, img.width, img.height, parentThis.state.text, parentThis.state.textLocation, parentThis.state.date, parentThis.state.showText, parentThis.state.type);
            showCanvas();
          }
          img.src = window.URL.createObjectURL(file);
        }
      }else{
        let img = {}
        img.width = 300;
        img.height = 300;
        generateElements(layoutWidth, layoutHeight, img.width, img.height, parentThis.state.text, parentThis.state.textLocation, parentThis.state.date, parentThis.state.showText, parentThis.state.type);
        showCanvas();
      }

      this.generateAndShowSVG();
      
      if(event){
        event.preventDefault();
      }
      
  }

  generateAndShowSVG(){
    var img;

    if(document.getElementById('uploadfield').files[0]){ // Checks if image has been selected (won't show anything if not)
      let file = document.getElementById('uploadfield').files[0]
      if(file.type.startsWith('image/')){ // Checks if image has valid type

        // https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
        function getBase64(file) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        }

        var img = document.createElement("img");
        //img.src = window.URL.createObjectURL(file);

        img = new Image(); // Creates new image object for canvas

        var layoutWidth = this.state.layoutWidth;
        var layoutHeight = this.state.layoutHeight;

        var parentThis = this;
        img.onload = function() {
          //generateElements(layoutWidth, layoutHeight, img.width, img.height, parentThis.state.text, parentThis.state.date);


                    //////
                    // Check for logo upload
                    //////
                    if(document.getElementById('logofield').files[0]){ // Checks if image has been selected (won't show anything if not)
                      let logo = document.getElementById('logofield').files[0]
                      if(logo.type.startsWith('image/')){ // Checks if image has valid type

                        

                        var logoImg = document.createElement("img");
                        //img.src = window.URL.createObjectURL(file);

                        logoImg = new Image(); // Creates new image object for canvas

                        logoImg.onload = function() {
                          //generateElements(layoutWidth, layoutHeight, img.width, img.height, parentThis.state.text, parentThis.state.date);

                          var logoData = '';
                          function setLogoData(data){
                            logoData = data;
                          }

                          getBase64(logo).then(
                            data => setLogoData(data)
                          ).then(
                            data => parentThis.setState({
                              svgElement: drawSVG(data, logoData),
                              //logoData: logoData,
                              buttonVisible: 'none',
                              downloadText: '',
                              downloadClass: ''
                            })
                          );
                          
                        }

                        logoImg.src = window.URL.createObjectURL(logo);
                        // 

                        /*
                          if(logo) => 
                          img.then.logoImg.then.setState

                        */
                      }
                    }else{
                      getBase64(file).then(
                        data => parentThis.setState({
                          svgElement: drawSVG(data),
                          buttonVisible: 'none',
                          downloadText: '',
                          downloadClass: ''
                        })
                      );
                    }


                    //// END ////

          
          
        }

        img.src = window.URL.createObjectURL(file);
        // 

        /*
          if(logo) => 
          img.then.logoImg.then.setState

        */
      }
    }
    
  }

  generatePNG(event){
    var canvas = document.getElementById('layout');
    if (canvas.getContext) {
      this.setState({
        downloadLink: canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"),
        downloadText: 'Download PNG',
        downloadClass: 'download--link',
        downloadFileName: 'OKV-' + this.state.layoutWidth + 'x' + this.state.layoutHeight + '.png'
      });
    }
    event.preventDefault();
  }

  generateJPEG(event){
    var canvas = document.getElementById('layout');
    if (canvas.getContext) {
      this.setState({
        downloadLink: canvas.toDataURL("image/png").replace("image/jpeg", "image/octet-stream"),
        downloadText: 'Download Jpeg',
        downloadClass: 'download--link',
        downloadFileName: 'OKV-' + this.state.layoutWidth + 'x' + this.state.layoutHeight + '.jpg'
      });
    }
    event.preventDefault();
  }

  addGeneratedLayout(){
    this.setState({generatedLayout: document.querySelector('.pagelayoutcontainer').innerHTML})
  }

  generatePDFCode(event){
    this.setState({
      generatedLayout: document.querySelector('.pagelayoutcontainer').innerHTML,
      buttonVisible: 'inline',
      downloadText: '',
      downloadClass: ''
    })


    event.preventDefault();
  }

  render(){
    return(
      <div class="parameters">
        <form method="post" action="https://apps.josworld.org/okv-render/index.php" enctype="multipart/form-data">
        <label for="uploadfield">
          Kunstwerk (enkel Jpeg, PNG of GIF): </label>
          <input name="image" type="file" id="uploadfield" onChange={this.handleImageInput}/><br/>
        <label>Dpi: <strong class="dpi"></strong></label><br/>
      
        <label for="logofield">
          Extra logo (enkel Jpeg, PNG of GIF): </label>
        <input name="logo" type="file" id="logofield" onChange={this.handleImageInput}/>
        <h2>Formaat: </h2>
        <select name="template" id="template" onChange={this.handleInputChange} >
          <option value="">-- Eigen formaat --</option>
          <option value="210x297">A4 (staand)</option>
          <option value="297x210">A4 (liggend)</option>
          <option value="297x420">A3 (staand)</option>
          <option value="420x297">A3 (liggend)</option>
        </select><br/>


        <label for="input-x">Breedte (in mm/px): </label>
        <input name="layoutWidth" type="number" id="input-x" onChange={this.handleInputChange} value={this.state.layoutWidth}/><br/>
        <label for="input-y">Hoogte (in mm/px): </label>
        <input name="layoutHeight" type="number" id="input-y" onChange={this.handleInputChange} value={this.state.layoutHeight}/><br/>
        
        <h2>Inhoud: </h2>
        <label for="showText">Toon tekst</label>
        <input name="showText" id="showText" type="checkbox" onChange={this.handleInputChange} defaultChecked={this.state.showText}/><br/>
        <label for="text">Regel 1: </label>
        <input name="text" id="text" type="text" onChange={this.handleInputChange} value={this.state.text}/><br/>
        <label for="textLocation">Regel 2: </label>
        <input name="textLocation" id="textLocation" type="text" onChange={this.handleInputChange} value={this.state.textLocation}/><br/>
        <label for="date">Regel 3:</label> 
        <input name="date" id="date" type="text" onChange={this.handleInputChange} value={this.state.date}/><br/>
        
        
        <textarea name="generatedLayout" class="generatedLayout" value={this.state.generatedLayout} style={{display: 'none'}}/>
        <button onClick={this.generatePreview}>Genereer pagina</button> <br/>
        <button onClick={this.generatePDFCode}>Genereer PDF voor print (in mm)</button> <br/>
        <button onClick={this.generatePNG}>Genereer PNG voor online (in px)</button> 
        <button onClick={this.generateJPEG}>Genereer Jpeg voor online (in px)</button> <br/>
        <input type="submit" value="Download PDF" onClick={this.submitAction} name="submit" style={{display: this.state.buttonVisible}}/>
        <br/>
        {/*<label>
          Formaat: 
          <input type="text" name="format" />
        </label>*/}
        
        </form>
        
        
        {/*<button onClick={this.generateAndShowSVG}>SVG</button> 
        <button onClick={this.downloadSVG}>Download SVG</button> 
        <button onClick={this.generateAndShowCanvas}>PNG</button>

        
        <a id="download">Download</a>*/}
        
        <p class="pagelayoutcontainer">{this.state.svgElement}</p>
        <p><a download={this.state.downloadFileName} href={this.state.downloadLink} class={"button download--link--button " + this.state.downloadClass}>{this.state.downloadText}</a></p>
      </div>
    );
  }
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <PageHeader/>
        <Layout/>
        <Parameters/>
        <PageFooter/>
      </div>
    );
  }
}

export default App;
